import React, { useEffect } from 'react';
import { Typography, makeStyles, createMuiTheme, Dialog, DialogTitle, ThemeProvider, CssBaseline, Grid } from '@material-ui/core'
import furImg from './fur3.jpg'
import imgSeal from './seal.jpg'
import storyData from './wolfWiki.json'

import './App.css';
import { yellow, blue } from '@material-ui/core/colors';

type XSType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface Story {
	id: number
	title: string
	text: string[]
	mainImage?: string
	rightImage?: string
	rightImageText?: string
	rightImageWidth?: number
}

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: blue[500],
			contrastText: "#FFF"
		},
		secondary: yellow,
	},
	typography: {
		fontSize: 16,
		fontFamily: "'Chelsea Market', 'Noto Serif JP', Alegreya, 'Playfair Display', Roboto",
	},
})

const useStyles = makeStyles(theme => ({
	root: {
		backgroundImage: "url(" + furImg + ")",
		backgroundRepeat: 'repeat',
		paddingBottom: '30px',
		userSelect: 'none',
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '30px',
		},
		backgroundColor: '#222329',
		color: 'white',
		//boxShadow: '2px 2px 4px black',
	},
	body: {
		[theme.breakpoints.down('md')]: {
			margin: '0',// 20px 30px 20px',
			padding: '20px 30px 20px 20px',
		},
		[theme.breakpoints.up('lg')]: {
			margin: '0 200px 30px 200px',
			padding: '50px 60px 50px 50px',
		},
		backgroundColor: 'white',
	},
	image: {
		[theme.breakpoints.only('xs')]: {
			maxWidth: '100%',
			marginRight: '10px',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			maxWidth: '70px',
			marginRight: '10px',
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: '100px',
			marginRight: '20px',
		},
		marginTop: '6px',
		verticalAlign: 'top',
		borderRadius: '4px',
		textAlign: 'center',
		cursor: 'pointer',
	},
	noImage: {
		[theme.breakpoints.only('xs')]: {
			padding: '20px 0px',
			marginRight: '10px',
			maxWidth: '100%',
			display: 'none'
		},
		[theme.breakpoints.between('sm', 'md')]: {
			padding: '20px 2px',
			marginRight: '10px',
			maxWidth: '65px',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '15px',
			marginRight: '20px',
			maxWidth: '90px',
		},
		overflow: 'hidden',
		marginTop: '6px',
		backgroundColor: '#222329',
		color: 'lightgrey',
		borderRadius: '4px',
		boxShadow: '0 0 4px lightgrey',
		textAlign: 'center',
		fontSize: '8pt',
		fontWeight: 600,
	},
	rightImageBox: {
		borderRadius: '4px',
		backgroundColor: 'white',
		overflow: 'hidden',
		boxShadow: '0 0 15px grey',
		float: 'right',
		margin: '0 0 25px 45px',
		textAlign: 'center',
		cursor: 'pointer',
		marginTop: '5px',
	},
	imageHeader: {
		fontSize: '9pt',
		padding: '5px 10px',
		backgroundColor: '#222329',
		color: 'white',
		textAlign: 'left',
	},
	title: {
		textTransform: 'uppercase',
		fontWeight: 700,
		margin: '0 0 12px 0',
		letterSpacing: '0.1em',
		color: 'red',
	},
	para: {
		marginBottom: '12px',
		lineHeight: '1.3em',
		textAlign: 'justify',
		textJustify: 'inter-word',
		letterSpacing: '0em',
	},
	trRoot: {
		verticalAlign: 'top',
	},
	menu: {
		overflow: 'scroll-x',
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: '#323339',
		padding: '0 5px',
	},
	menuItem: {
		backgroundColor: '#323339',
		margin: '3px 0 6px 0',
		borderRight: '1px solid #505050',
		padding: '0 6px',
	},
	menuItemText: {
		padding: '2px 6px',
		color: 'white',
		fontWeight: 400,
		fontSize: '9pt',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		textDecoration: 'none',
		"&:hover": {
			borderRadius: '5px',
			backgroundColor: 'lightblue',
			color: '#222329'
		}
	},
	menuItemSpacer: {
		flexGrow: 1,
		backgroundColor: '#323339',
		padding: '2px 10px',
	},
	cell: {
		border: 0,
	},
}))


function App() {

	const classes = useStyles()
	const stories = storyData as Story[]

	const Title = ({ id, title, onClick }: { id: number, title: string, onClick?: () => void }) => {
		return (
			<div style={{ display: 'flex' }}>
				<div onClick={onClick} className={classes.title}>
					{id}.&nbsp;{title}&nbsp;
		</div>
				<div style={{ flexGrow: 1, marginTop: '-15px', marginBottom: '15px', borderBottom: '1px solid red' }}>
					&nbsp;
		</div>
			</div>
		)
	}

	const createMarkup = (para: string) => {
		//const reBold = /(.*)\[(.*)\](.*)/g
		const reBold = /(.*)\[(.*)\](.*)/g
		const reRedact = /(.*)\|(.*)\|(.*)/g
		const reIcon = /(.*)\{(.*)\}(.*)/g
		const reBookmark = /(.*)#(.*):(.*)#(.*)/g

		let rawPara = ''
		let redPara = para
		do {
			rawPara = redPara
			redPara = rawPara.replace(reRedact, '$1<span class="redacted">$2</span>$3')
		} while (redPara !== rawPara)

		let boldPara = redPara
		do {
			rawPara = boldPara
			boldPara = rawPara.replace(reBold, '$1<i>$2</i>$3')
		} while (boldPara !== rawPara)

		let iconPara = boldPara
		do {
			rawPara = iconPara
			iconPara = rawPara.replace(reIcon, '$1<i class="fal fa-$2" />$3')
		} while (iconPara !== rawPara)

		let bookmarkPara = iconPara
		do {
			rawPara = bookmarkPara
			bookmarkPara = rawPara.replace(reBookmark, '$1<a href="#sid$2">$3</a>$4')
		} while (bookmarkPara !== rawPara)

		return { __html: bookmarkPara }
	}

	const Menu = ({ stories }: { stories: Story[] }) => (
		<div className={classes.menu}>
			{stories.map((story, key) => (
				<div className={classes.menuItem}>
					<a className={classes.menuItemText} key={key} href={'#sid' + story.id}>{story.title.replace('The ', '')}</a>
				</div>
			))}
			<div className={classes.menuItemSpacer}>&nbsp;</div>
		</div>
	)

	const ImageZoom = ({ image, text, width, isMain }: { image: string, text?: string, width?: number, isMain?: boolean }) => {
		const [open, setOpen] = React.useState(false)

		return (
			<React.Fragment>
				{isMain && <img src={require("./" + image)} className={classes.image} alt='' onClick={() => setOpen(true)} />}

				{!isMain && <div className={classes.rightImageBox} onClick={() => setOpen(true)}>
					<div className={classes.imageHeader}>
						{text}
					</div>
					<img src={require("./" + image)} width={(width || 150) + 'px'} style={{ marginBottom: '-6px' }} alt={text} />
				</div>}

				<Dialog open={open} onClose={() => setOpen(false)}>
					{text && <DialogTitle style={{ backgroundColor: '#222329', color: 'white', marginTop: '-2px', }}>
						{text}
					</DialogTitle>}
					<img src={require("./" + image)} alt={text} width='100%' onClick={() => setOpen(false)} />
				</Dialog>
			</React.Fragment>
		)
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className={classes.root}>
				<header className={classes.header}>
					<Typography variant="h4">
						Welcome to WLB!
		 		 </Typography>
					<Typography variant="body2">
						<br />
						<strong>Wolf Loves Biscuits</strong> is where we document the little-known knowledge of the Wolves of Wolfland: their history, their curious and intriguing
					inventions, biscuit preferences and other aspects of their life in the secretive and mysterious 'Wolfland'. No-one actually knows for sure where Wolfland is.
					</Typography>
				</header>

				<Menu stories={stories} />

				<div className={classes.body}>

					<Grid container spacing={1}>

						<Grid item xs={12} sm={2}>
						</Grid>
						<Grid item xs={12} sm={10}>
							<a href="https://www.youtube.com/channel/UCa-X12kaVffQWb7kiKQ8anQ" rel="noopener noreferrer" target="_blank" style={{ float: 'left' }}>
								<i className='fab fa-youtube' style={{ color: 'red' }}></i>
										&nbsp;Wolf on YouTube
							</a>
							<div style={{ verticalAlign: 'text-bottom', float: 'right' }}>
								<img src={imgSeal} width={25} alt='' />
								&nbsp;Check out my friend's new website:&nbsp;
								<a href="https://sealslovefish.com" rel="noopener noreferrer" target="_blank">
									<strong>SealsLoveFish.com</strong>
								</a>
							</div>
						</Grid>

						{stories.map((story, key) => (
							<React.Fragment key={key}>

								<Grid item xs={12}>
								</Grid>

								<Grid item xs={12} sm={2} id={'sid' + story.id} style={{ marginTop: '30px' }}>
									{story.mainImage && <ImageZoom image={story.mainImage} text={story.title} isMain />}
									{!story.mainImage && <div className={classes.noImage} >
										IMAGE NOT AVAILABLE
									</div>}
								</Grid>
								<Grid item xs={12} sm={10} style={{ flexGrow: 1, marginTop: '30px' }}>
									<Title id={story.id} title={story.title} />
									{story.rightImage && <ImageZoom image={story.rightImage} text={story.rightImageText} width={story.rightImageWidth} />}
									{story.text.map((para, key) => (<div key={key} className={classes.para} dangerouslySetInnerHTML={createMarkup(para)} />))}
								</Grid>

							</React.Fragment>
						))}

					</Grid>

				</div>
			</div >
		</ThemeProvider >
	)
}

export default App;
